.exchange-option-dnd-row {
  .smooth-dnd-container {
    min-height: 300px;
    border: 2px $border-color-1 dashed;
    border-radius: 10px;
    padding: 17px;
    margin: 10px 0;
  }
  .dnd-btn {
    margin: 7px 7px;
    border: 1px solid $oxford-blue;
    display: flex;
    text-align: center;
    line-height: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: $light-color-1;
    min-height: 40px;
    cursor: pointer;
    align-items: center;
    h5 {
      margin-bottom: 0;
      font-size: 16px;
      text-align: left;
      line-height: 22px;
      display: flex;
      .text {
        font-size: 16px;
      }
    }
    &:hover {
      -webkit-box-shadow: -1px 1px 6px 1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: -1px 1px 6px 1px rgba(0, 0, 0, 0.4);
      box-shadow: -1px 1px 6px 1px rgba(0, 0, 0, 0.4);
      color: $shuttle-gray;
    }
  }

  .order-column {
    width: 70px;
    flex-basis: inherit;
    flex-grow: inherit;
  }

  //   Value container style
  .value-container {
    .dnd-btn {
      border: none;
      background: $oxford-blue;
      color: $light-color-1;
      &:hover {
        color: $light-color-2;
      }
      .order-number {
        font-size: 16px;
        padding-right: 5px;
        border-right: 1px solid $shuttle-gray;
        margin-right: 5px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
  &.invalid-row {
    .smooth-dnd-container {
      border-color: $input-error-border-color;
    }
  }
}

.exchange-input {
  height: 0;
  width: 0;
  border: 0;
  outline: none;
}
