.application-apply-option-card .card-header {
  background-color: $light-color-1;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $primary-color;
  border-color: $primary-color;
}
/* General UI Configuration Section */
.tenant-card {
  input {
    &[type='file'] {
      max-width: 100%;
    }
  }
  .card-header,
  .card-body {
    padding: 48px 40px;
  }
}
/* End General UI Configuration Section */

.floating-button-container-height-margin {
  margin-bottom: 140px;
}

/* Floating Action button styles */
.floating-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $light-color-1;
  z-index: 6;
  width: 100%;

  .shadow-div {
    width: 100%;
    height: 1px;
    background-color: $light-color-1;
    box-sizing: border-box;
    @include top-shadow;
  }
  .btn {
    width: 120px;
  }

  .container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin: auto 12px;
      border: none;
      box-shadow: $btn-shadow;
    }
  }
}
.branch-config-option-row {
  background: #fafafa;
  padding: 10px;
  margin-bottom: 15px;
}
/* End Floating actions button styles */

.branch-card {
  .tj-button-primary {
    min-width: 200px;
  }
}

ul.tab-menu {
  padding: 0;
  margin: 0 -10px;
  font-weight: bold;
  font-size: 16px;

  li {
    display: block;
    float: left;
    margin: 0 10px;
    span {
      margin-bottom: 10px;
      display: inline-block;
    }
    &.active {
      color: #7637f2;
      border-bottom: 2px solid #7637f2;
    }
  }
}
