@keyframes AppLogoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: $normal-font-name;
  background-color: $light-color-1 !important;
  min-height: 100vh;
  .section-title {
    margin-top: 56px;
  }
}
h1,
h2,
h3,
h4,
h5 {
  font-family: $heading-font-name;
}
.brand-wrapper {
  margin-top: 10px;
}

.form-addcustomer {
  .button {
    width: 172px;
    font-size: 16px;
  }
}

.login-card {
  border: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43) !important;
  overflow: hidden !important;
  height: 522px;
  .card-body {
    padding: 85px 60px 60px;
  }
  form {
    max-width: 326px;
  }
  .form-control {
    border: 1px solid $light-color-10;
    padding: 15px 25px;
    min-height: 45px;
    font-size: 13px;
    line-height: 15;
    font-weight: normal;
    &::-webkit-input-placeholder {
      color: $light-color-9;
    }
    &::-moz-placeholder {
      color: $light-color-9;
    }
    &:-ms-input-placeholder {
      color: $light-color-9;
    }
    &::-ms-input-placeholder {
      color: $light-color-9;
    }
    &::placeholder {
      color: $light-color-9;
    }
  }
  .login-btn {
    padding: 13px 20px 12px;
    background: $gradient-bg-pattern-2;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: $light-color-1;
    margin-bottom: 24px;
    &:hover {
      border: 1px solid $dark-color-2;
      color: $light-color-1;
    }
  }

  .button {
    width: 100%;
    font-size: 17px;
    margin-bottom: 10px;
  }

  .forgot-password-link {
    font-size: 14px;
    color: $light-color-9;
    margin-bottom: 12px;
  }
}
.login-card-img {
  border-radius: 0;
  width: 100%;
  height: 522px;
  -o-object-fit: cover;
  object-fit: cover;
}
.login-card-description {
  font-size: 25px;
  color: $dark-color-2;
  font-weight: normal;
  margin-bottom: 23px;
}
.login-card-footer-text {
  font-size: 16px;
  color: $dark-color-5;
  margin-bottom: 60px;
}
.login-card-footer-nav {
  a {
    font-size: 14px;
    color: $light-color-9;
  }
}
.limiter {
  width: 100%;
  margin: 0 auto;
}
.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: $light-color-8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}
.wrap-table100 {
  width: 1300px;
}
table {
  width: 100%;
  background-color: $light-color-1;
  table-layout: fixed;
}
th {
  font-weight: unset;
  padding-right: 10px;
  font-size: 18px;
  font-family: $heading-font-name, sans-serif;
}
td {
  font-weight: unset;
  padding-right: 10px;
}
.column100 {
  width: 130px;
  padding-left: 25px;
}
.column100.column1 {
  width: 265px;
  padding-left: 42px;
}
.row100.head {
  th {
    padding-top: 24px;
    padding-bottom: 20px;
  }
}
.row100 {
  td {
    padding-top: 18px;
    padding-bottom: 14px;
  }
}
.table100.ver1 {
  td {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 14px;
    color: $light-color-4;
    line-height: 1.4;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: $dark-color-3;
  }
  .row100 {
    &:hover {
      background-color: $light-color-7;
    }
    td {
      &:hover {
        background-color: $link-bg-color-1;
        color: $light-color-1;
      }
    }
  }
  .hov-column-ver1 {
    background-color: $light-color-7;
  }
  .hov-column-head-ver1 {
    background-color: $dark-color-4 !important;
  }
}
.table100.ver2 {
  td {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 14px;
    color: $light-color-4;
    line-height: 1.4;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #333333;
  }
  .row100 {
    &:hover {
      td {
        background-color: #83d160;
        color: $light-color-1;
      }
    }
    td {
      &:hover {
        background-color: #57b846;
        color: $light-color-1;
      }
    }
  }
  .hov-column-ver2 {
    background-color: #83d160;
    color: $light-color-1;
  }
  .hov-column-head-ver2 {
    background-color: #484848 !important;
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #eaf8e6;
      }
    }
  }
}
.table100.ver3 {
  tbody {
    tr {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  td {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 14px;
    color: $light-color-4;
    line-height: 1.4;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #6c7ae0;
  }
  .row100 {
    &:hover {
      td {
        background-color: #fcebf5;
      }
    }
    td {
      &:hover {
        background-color: #e03e9c;
        color: $light-color-1;
      }
    }
  }
  .hov-column-ver3 {
    background-color: #fcebf5;
  }
  .hov-column-head-ver3 {
    background-color: #7b88e3 !important;
  }
}
.table100.ver4 {
  td {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 14px;
    color: $light-color-4;
    line-height: 1.4;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #fa4251;
  }
  .row100 {
    &:hover {
      td {
        color: #fa4251;
      }
    }
    td {
      &:hover {
        background-color: #ffebed;
        color: #fa4251;
      }
    }
  }
  .hov-column-ver4 {
    background-color: #ffebed;
  }
  .hov-column-head-ver4 {
    background-color: #f95462 !important;
  }
}
.table100.ver5 {
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #e9faff;
      }
    }
  }
  td {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 14px;
    color: $light-color-4;
    line-height: 1.4;
    position: relative;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #002933;
  }
  .row100 {
    &:hover {
      td {
        color: $secondary-color;
      }
    }
    td {
      &:hover {
        color: $secondary-color;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          pointer-events: none;
          border: 1px solid $secondary-color;
        }
      }
    }
  }
  .hov-column-ver5 {
    color: $secondary-color;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      border-left: 1px solid #f2f2f2;
      border-right: 1px solid #f2f2f2;
    }
  }
  .hov-column-head-ver5 {
    background-color: #1a3f48 !important;
    color: $secondary-color;
  }
}
.table100.ver6 {
  border-radius: 16px;
  overflow: hidden;
  @include primary-gradient;
  table {
    background-color: transparent;
  }
  td {
    font-family: Montserrat-Regular, sans-serif;
    font-size: 14px;
    color: $light-color-1;
    line-height: 1.4;
  }
  th {
    font-family: Montserrat-Medium, sans-serif;
    font-size: 12px;
    color: $light-color-1;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.32);
  }
  .row100 {
    &:hover {
      td {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    td {
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
  .hov-column-ver6 {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
.App {
  background-color: $light-color-1;
}
.body {
  background-color: $light-color-1 !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.adjustBody {
  padding-top: 10%;
}
.logOut {
  float: right;
  margin-top: 22px !important;
}
.c-logocontainer {
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 180px;
  height: 80px;
  margin-right: 12px;
  padding: 16px 24px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: $light-color-1;
}
.c-nav-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  height: 80px;
  -webkit-transition: -webkit-transform 0.2s ease;
  transition: -webkit-transform 0.2s ease;
  transition: transform 0.2s ease;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease;
}
.c-newnav {
  display: block;
  background-color: $light-color-1;
  border-bottom: 1px solid $light-color-3;
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}
.addTenant {
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  float: left;
  width: 100%;
  color: #444;
  text-align: left;
  img {
    margin-right: 15%;
  }
}
.cn-intro_img-right {
  max-width: 730px;
  margin-left: 12%;
}
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px $dark-color-2;
  box-shadow: 0px 0px 0px 0px $dark-color-2;
}
.text-on-pannel {
  background: $light-color-1 none repeat scroll 0 0;
  height: auto;
  padding: 3px 5px;
  position: absolute;
  margin-top: -50px;
  border-radius: 8px;
  width: 4%;
}
.panel-body {
  padding: 30px 2% 2% 2%;
}
img.rounded-corners {
  width: 25%;
}
.alignLogo {
  width: 25%;
}
.tableRowStyle {
  border-style: none !important;
  td {
    width: 25%;
  }
}
.tenantTableStyle {
  border-color: $light-color-1 !important;
}
.tenant-card {
  border: 0 !important;
  border-radius: 6px;
  background-color: $light-color-1;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  margin-top: 3%;
}
.tenantheader {
  margin-top: 140px;
  margin-left: 0em;
  justify-content: space-between;
}
.tenantListStyle {
  max-height: 300px;
  overflow-y: scroll;
}
.fill {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: $gradient-bg-pattern-2;
  box-shadow: 0 4px 6px 0 rgba(38, 44, 51, 0.11),
    0 1px 3px 0 rgba(0, 0, 0, 0.08);
  border: 0px;
}
.main-button {
  background: $gradient-bg-pattern-2 !important;
  border: none;
}
.back-button {
  border-radius: 20px;
  background-color: $light-color-1;
  box-shadow: $btn-shadow;
  color: $light-color-2;
  border-color: $light-color-4;
  letter-spacing: 0;
  &:hover {
    background-color: $light-color-1;
    color: $dark-color-2;
    border: 1px solid $dark-color-2;
  }
}
.buttons-circular-40-40-px-crea {
  height: 40px;
  width: 40px;
}

svg {
  cursor: pointer;
}
h2 {
  font-family: HaufeMerriweatherSans-Bold, sans-serif;
  font-size: 24px;
}
label {
  font-size: 18px;
}
span {
  font-family: HaufeMerriweatherSansLt Regular Webfont, sans-serif;
  font-size: 13px;
}
.table {
  td {
    &:nth-child(5) {
      width: 7%;
      text-align: center;
    }
    vertical-align: middle;
    border-top: 1px solid $light-color-3;
  }
  th {
    &:nth-child(5) {
      width: 7%;
      text-align: center;
    }
    vertical-align: middle;
    border-top: 1px solid $light-color-3;
  }
}
.editForm {
  margin-top: 10%;
}
.App-header {
  background-color: $dark-color-1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $link-color-1;
}
.App-link {
  color: $link-color-1;
}
.row-justify {
  justify-content: center;
}
.heading {
  border-bottom: $light-color-4 1px solid;
  margin-top: 15px;
}
.errormsg {
  color: $input-error-color;
  position: absolute;
}
.round-button {
  border-radius: 20px !important;
}
.talent-textbox {
  box-sizing: border-box;
  height: 48px;
  width: 400px;
  border: 1px solid $light-color-6;
  border-radius: 4px;
  background-color: $light-color-1;
}

.card-header-custom {
  color: $dark-color-2;
  background-color: transparent;
  border-top: none !important;
  font-weight: bold;
  display: flex;
  .tj-tooltip-container {
    margin-left: 8px;
  }
}
.card-template {
  display: grid;
  grid-template-columns: 300px;
  grid-template-rows: 0px 210px 80px;
  grid-template-areas: 'image' 'text' 'stats';
  background: $light-color-1;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
  font-family: roboto, sans-serif;
  text-align: center;
  border-radius: 0.25rem;
}
.card-image-template {
  grid-area: image;
}
.card-text-template {
  grid-area: text;
  margin: 25px;
  .date {
    color: $light-color-5;
    font-size: 13px;
  }
  p {
    color: $light-color-4;
    font-size: 15px;
    font-weight: 300;
  }
  h2 {
    margin-top: 0px;
    font-size: 28px;
  }
}
.card-stats-template {
  grid-area: stats;
  display: grid;
  grid-template-columns: 1fr 0fr 0fr;
  grid-template-rows: 0fr;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: rgb(255, 7, 110);
}
.card-image {
  grid-area: image;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-size: cover;
}
.stat {
  color: $light-color-1;
  font-size: 20px;
}
.content-para {
  text-align: center;
  width: 40%;
  margin: 0 auto;
}
.mandatory-mark {
  color: $mandatory-indicator-color;
}
.sub-header {
  border-top: none;
  border-bottom: none;
}
@include mq('mobile') {
  .login-card {
    .card-body {
      padding: 35px 24px;
    }
  }
}
@include mq('tablet') {
  .login-card-footer-text {
    margin-bottom: 24px;
  }
}

@include customMQ('360px', '641px') {
  .table {
    td {
      &:nth-child(3) {
        display: none;
      }
    }
    th {
      &:nth-child(3) {
        display: none;
      }
    }
  }
  img.rounded-corners {
    width: 100%;
  }
  td {
    font-size: x-small;
  }
  th {
    font-size: 14px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: AppLogoSpin infinite 20s linear;
  }
}

.checkbox-wrapper {
  display: flex;

  .tj-checkbox {
    align-self: flex-start;
    margin-top: 15px;
  }

  .label {
    align-self: flex-start;
    margin-left: 10px;
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.4;
    color: #212529;
  }
}

.tj-notification-item-icon {
  svg {
    cursor: text;
  }
}
