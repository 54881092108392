$primary-color: #4801ff;
$secondary-color: #fe3e64;
$input-error-color: #dc3545;
$oxford-blue: #232a36;
$shuttle-gray: #636871;
$light-color-1: #fff;
$light-color-2: #8f9299;
$light-color-3: #ced4da;
$light-color-4: #808080;
$light-color-5: #ed1e79;
$light-color-6: #dce0e6;
$light-color-7: #f2f2f2;
$light-color-8: #d1d1d1;
$light-color-9: #919aa3;
$light-color-10: #d5dae2;

$link-color-1: #61dafb;
$link-bg-color-1: #6c7ae0;

$dark-color-1: #282c34;
$dark-color-2: #000000;
$dark-color-3: #36304a;
$dark-color-4: #484848;
$dark-color-5: #0d2366;

$input-error-border-color: $input-error-color;

$gradient-color-1: #f050a7;
$gradient-color-2: #8800ff;
$gradient-color-3: #3e45ff;
$gradient-bg-pattern: linear-gradient(
  90deg,
  $gradient-color-1 0%,
  $gradient-color-2 63%,
  $gradient-color-3 100%
);
$gradient-bg-pattern-2: linear-gradient(
  45deg,
  $gradient-color-1 0%,
  $gradient-color-2 71.69%,
  $gradient-color-3 100%
);

$btn-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.08);

$border-color-1: $light-color-2;
$border-color-2: $light-color-3;
$mandatory-indicator-color: $input-error-color;

$heading-font-name: 'HaufeMerriweatherSans Regular Webfont';
$normal-font-name: 'HaufeMerriweatherSansLt Light Webfont';
$generic-font-family: 'sans-serif';
