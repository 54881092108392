::-webkit-scrollbar {
  height: 80px;
  width: 3px;
  background-color: #d2d4d6;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
  &:hover {
    background: #555;
  }
}
