@mixin mq($point) {
  // mq = Media Query
  @if $point == mobile {
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
      @content;
    }
  } @else if $point == tv {
    @media only screen and (min-device-width: 1201px) {
      @content;
    }
  }
}

@mixin customMQ($min, $max) {
  // customMQ = Custom media query
  @media only screen and (min-device-width: $min) and (max-device-width: $max) {
    @content;
  }
}
