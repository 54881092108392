/*Font Family by Haufe*/
@font-face {
  font-family: HaufeMerriweatherSans Regular Webfont;
  src: url(../font/HFM_sans/HaufeMerriweatherSans.ttf);
}

@font-face {
  font-family: HaufeMerriweatherSansLt Light Webfont;
  src: url(../font/HFM_sans/HaufeMerriweatherSansLt.ttf);
}

@font-face {
  font-family: HaufeMerriweatherSans-Bold;
  src: url(../font/HFM_sans/HaufeMerriweatherSans-Bold.ttf);
}
