.tenant-action {
  position: relative;
  z-index: 1;
  margin-right: 15px;
  &.active {
    .round-btn {
      color: $primary-color;
    }
  }
  .round-btn {
    height: 40px;
    width: 40px;
    color: #747c87;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px 0 rgba(38, 44, 51, 0.11),
      0 1px 3px 0 rgba(0, 0, 0, 0.08);
    border: none;

    &:hover {
      color: $primary-color;
    }
    img {
      width: 100%;
    }
  }

  .option-container {
    position: absolute;
    top: 48px;
    right: 0;
    width: 220px;
  }
}
